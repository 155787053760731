import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import HSAImage from '../../images/image_HSA.jpg';

const Enrollment = () => (
  <ServicesLayout title="Health Savings Accounts" bannerTitle="Health Savings Accounts" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">Health Savings Accounts (HSA)</h2>
    <p className="inner-page__lead">Offered to employees enrolled in a high-deductible health plan, an HSA lets employees set aside money on a pre-tax basis to pay for qualified medical, dental, and vision expenses.</p>
    <img className="inner-page__hero" src={HSAImage} alt="Medical bill, calculator, stethoscope, and money on a desk" />
    <p>For maximum tax savings, HSAs can be paired with a Limited FSA.</p>

    <p>Triple Tax Savings: Funds go in tax-free, grow tax-free, and are distributed tax-free for qualified expenses.</p>

    <p>Other Perks: Accounts are individually owned and portable; plus, unused funds rollover and have investment options.</p>

    <p className="inner-page__lead">How can Tri-Star help administer your employee benefits? Call us at <a href="tel:3145764022">314.576.4022</a> or email us at <a href="mailto:info@tri-starsystems.com">info@tri-starsystems.com</a>.</p>
  </ServicesLayout>
);

export default Enrollment
