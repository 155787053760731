import './ServicesLayout.scss';

import { Link } from 'gatsby';
import React from 'react';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import SubHeader from '../../components/SubHeader';

import PigbankIcon from '../../icons/pigbank-icon.svg';
import MoneyIcon from '../../icons/money-icon.svg';
import PaperPenIcon from '../../icons/paper-pen-icon.svg';
import HeartHandIcon from '../../icons/heart-hand-icon.svg';
import CobraIcon from '../../icons/cobra-icon.svg';
import HandDollarIcon from '../../icons/hand-dollar-icon.svg';
import ReimbursementIcon from '../../icons/reimbursement-icon.svg';
import LOAIcon from '../../icons/user-clock-solid.svg';


const innerPages = [
  {
    to: '/services/on-leave',
    title: 'Leave of Absence',
    icon: LOAIcon
  },
  {
    to: '/services/cobra-administration',
    title: 'COBRA Administration',
    icon: CobraIcon
  },
  {
    to: '/services/enrollment-benefit-admin',
    title: 'Online Enrollment & Benefit Admin Services',
    icon: PaperPenIcon
  },
  {
    to: '/services/flexible-spending-accounts',
    title: 'Flexible Spending Accounts (FSA)',
    icon: MoneyIcon
  },
  {
    to: '/services/health-reimbursement-arrangements',
    title: 'Health Reimbursement Arrangements (HRA)',
    icon: HandDollarIcon
  },
  {
    to: '/services/qsehra',
    title: 'Qualified Small Employer Health Reimbursement Arrangement (QSEHRA)',
    icon: ReimbursementIcon
  },
  {
    to: '/services/health-savings-accounts',
    title: 'Health Savings Accounts (HSA)',
    icon: PigbankIcon
  },
  {
    to: '/services/retiree-billing',
    title: 'Retiree Billing',
    icon: HeartHandIcon
  }
];

function ServicesLayout({
  title,
  bannerTitle,
  bannerCopy,
  bannerImage,
  children
}) {
  return (
    <Layout>
      <SEO title={title} />
                <SubHeader title="Services" />
      <section className="inner-page">
        <div className="inner-page__sidebar">
          <h3 className="inner-page__nav-header">All Services</h3>
          <nav className="inner-page__nav">
          {innerPages.map((page,
            i) => (
            <Link
              key={`${page.to}-${i}`}
              to={page.to}
              className="inner-page__nav-link"
              activeClassName="inner-page__nav-link--active"
            >
              <page.icon />
              {page.title}
            </Link>
          ))}
          </nav>
        </div>
        <div className="inner-page__main">
          {children}
        </div>
      </section>
    </Layout>
  );
}

export default ServicesLayout;
